import React, { useState, useEffect } from 'react';
import './CommentBlock.scss';
import axios from 'axios';
import { filterTextFormat } from '../../../services/rest/connect';

const CommentBlock = function(props) {
	const [comments, setComments] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const result = await axios(`${process.env.GATSBY_API_URL}/jsonapi/comment/comment_forum`);

      let filtredResult = [];
      for (let numData in result.data.data) {
        let data = { ...result.data.data[numData] };
        if (data.relationships.entity_id.data.id == props.uuid) {
          filtredResult[filtredResult.length] = data;
        }
      }
      if (filtredResult && filtredResult[0] && filtredResult[0].attributes) {
        setComments(filtredResult);
      }
    }
    fetchData();
  });
  
  let getComments = () => {
    return comments.map((comment) => {
      return <div className="p-comment" key={comment.id} >
        <h2 className="p" >{comment.attributes.subject}</h2>
        <div className="p-text" dangerouslySetInnerHTML={{__html: filterTextFormat((comment.attributes && comment.attributes.comment_body && comment.attributes.comment_body.value) ? comment.attributes.comment_body.value: '') }} ></div>
      </div>;
    });
  }

  return <div className="block block-comment">
    { getComments() }
  </div>;
}

export default CommentBlock;