import React, { Component } from 'react';
import Layout from '../../layout';
import './ForumPage.scss';
import Paper from '@material-ui/core/Paper';
import { filterTextFormat } from '../../../services/rest/connect';
import CommentBlock from '../../blocks/comments/CommentBlock';
import loadable from '@loadable/component'

class ForumPage extends Component {
  constructor () {
    super();
    this.state = {
      youtube: '',
      iframe: '',
      createComment: <></>
    };
  }

  componentDidMount () {
    const isBrowser = typeof window !== `undefined`;
    if (isBrowser) {
      setTimeout(() => {
        const CreateCommentBlock = loadable(() => import('../../blocks/comments/CreateCommentBlock'))

        this.setState({
          ...this.state, ...{
            createComment: <CreateCommentBlock/>,
          }
        });
      }, 1);
    }
  }

  removeElement () {

  }

  render() {
    return <Layout>
      <div className="page question" >
        <Paper elevation={0} className="p-principal" >
          <h1 className="p" >{this.props.pageContext.title}</h1>
          <div className="p-text" dangerouslySetInnerHTML={{__html: filterTextFormat(this.props.pageContext.body) }} ></div>
        </Paper>
        <CommentBlock uuid={this.props.pageContext.uuid} ></CommentBlock>
        { this.state.createComment }
      </div>
    </Layout>
  }
}

export default ForumPage;